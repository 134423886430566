body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bgImage: url("https://app.simplitpos.com/img/Isotipo-SimplitPOS.png"); 
  --main-bg-color0: #FFFFFF;
  --main-bg-color1: #666666;
  --main-bg-color3: #FB8303;
  --main-bg-color4: #3679E9;
  --main-bg-color5: #679635;
  --main-list-color0: #e1dddc;
  --main-hover-color0: #FB8303;
  --main-text-color0: #777475;
  --main-text-color1: #FFFFFF;
  --main-text-color2: #FB8303;
}